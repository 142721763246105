import React from "react";
import ScriptTag from "react-script-tag";

export default function Orders() {
  return (
    <div>
      <ScriptTag src="https://store.goorder.pl/goorder.js"></ScriptTag>
      <div className="orders__container">
        <div
          class="goorder"
          data-src="https://podjablonia.goorder.pl/widget?grid=1"
        ></div>
        <div id="db-root-widget"></div>
      </div>
    </div>
  );
}
