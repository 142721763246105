import React, { useState } from "react";
import Icons from "../Icons/Icons";
import logo from "../../assets/newLogo.png";
import { Link } from "react-router-dom";

const MENU_ITEMS = [
  {
    text: "Zamówienia",
    src: "goorder",
  },
  {
    text: "Wydarzenia",
    src: "wydarzenia",
  },
  {
    text: "Galeria",
    src: "galeria",
  },
  {
    text: "Kontakt",
    src: "kontakt",
  },
];

export default function Nav() {
  const [isOpen, toggle] = useState(false);

  return (
    <header className="header__container">
      <div className="header">
        <Link className="header__logo" to="/">
          <figure className="header__logoImgContainer">
            <img className="header__logoImg" src={logo} alt="logo" />
          </figure>
          <h1>pod jabłonią</h1>
        </Link>

        <div
          onClick={() => toggle(!isOpen)}
          className={isOpen ? `header__icon open` : `header__icon`}
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <nav
          className={
            isOpen ? `header__navigation navOpen` : `header__navigation`
          }
        >
          <ul className={isOpen ? `header__list ` : `header__list close`}>
            {MENU_ITEMS.map((item, i) => (
              <li
                onClick={() => toggle(false)}
                className="header__listItem"
                key={i}
              >
                <Link to={item.src} {...item}>
                  {item.text}
                </Link>
              </li>
            ))}

            <li className="header__listFooter">
              <div className="header__socials">
                <a
                  aria-label="Facebook"
                  href="https://www.facebook.com/restauracjapodjablonia/"
                >
                  <Icons iconName="facebook" />
                </a>
                <a
                  aria-label="Instagram"
                  href="https://www.instagram.com/restauracja_podjablonia/"
                >
                  <Icons iconName="instagram" />
                </a>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}
