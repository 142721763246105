import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";
import Home from "./Views/Home";
import Gallery from "./Views/Gallery";
import Nav from "./Components/Nav";
import Contact from "./Views/Contact";
import Events from "./Views/Events";
import Orders from "./Views/Orders";

function App() {
  return (
    <Router>
      <div className="App">
        <Nav />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/wydarzenia">
            <Events />
          </Route>
          <Route path="/galeria">
            <Gallery />
          </Route>
          <Route path="/kontakt">
            <Contact />
          </Route>
          <Route path="/goorder">
            <Orders />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
