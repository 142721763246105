import React from 'react'
import { Link } from 'react-router-dom'
// import logo from './../../assets/logo1.svg'
export default function Home() {
    return (
        <main className="home">
            <div className="home__container">
                <h2 className="home__text">


                    <span className="home__text--top">restauracja rodzinna</span>

                </h2>

                <Link to="/menu" className="home__button">MENU</Link>

            </div>
        </main>
    )
}
